@import 'external';
@import 'layout';
@import 'components';

/* beta mark in the left upper edge */
body:after {
  content: 'MVP';
  position: fixed;
  width: 110px;
  height: 30px;
  background: $primary;
  top: 10px;
  left: -30px;
  text-align: center;
  font-size: 15px;
  font-family: $font-family-sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  color: #fff;
  line-height: 30px;
  letter-spacing: 1px;
  transform: rotate(-45deg);
  z-index: 1000;
}
