.modal {
  .modal-footer {
    background: #f6f8fb;
    color: $secondary;
  }

  .modal-title {
    font-weight: 600;
    font-size: 1rem;
  }
}
