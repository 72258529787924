.card {
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .card-title {
      margin-bottom: 0;
    }
  }

  .card-title {
    font-size: 1rem;
  }

  .card-footer {
    background: #f6f8fb;
  }

  // Override header color
  .table > :not(caption) > * > th {
    background: rgb(246, 248, 251);
    color: #6c7a91;
    font-size: 0.625rem;
    text-transform: uppercase;
  }

  &-stats {
    .card-stats-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #6c7a91;

      .dropdown button {
        padding: 0;
        margin: 0;
        font-size: 0.875rem;
        background: none;
        color: inherit;
        border: 0;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .card-title {
      font-weight: 700;
      font-size: 0.625rem;
      letter-spacing: 0.04em;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    .card-value {
      line-height: 1;
      display: flex;
      align-items: end;
      gap: 0.7rem;
      font-weight: 600;

      .h1 {
        margin-bottom: 0;
      }
    }

    .card-trend {
      display: inline-flex;
      align-items: end;
    }
  }
}


.card-footer-actions {
  display: flex;
  justify-content: end;
  gap: .5rem;
}

.card-status-top {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 2px;
  border-radius: $card-border-radius $card-border-radius 0 0;
}