.toast {
  position: relative;

  &-status-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 2px;
    border-radius: $card-border-radius $card-border-radius 0 0;
  }
}