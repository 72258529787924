.dropdown-dots {
  $root: &;
  color: #666666;
  cursor: pointer;

  .dropdown-toggle::after,
  .dropdown-toggle::before {
    display: none !important;
  }

  #{$root}-btn {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }

  &:hover {
    color: #262626;
  }

  // Icons
  .tabler-icon {
    color: #666666 !important;
  }
}
