.form-control {
  box-shadow: rgba(24, 36, 51, 0.04) 0 2px 4px 0;
}

.form-group {
  margin-bottom: 1rem;

  .form-required {
    margin-left: 0.125rem;
    display: inline-block;
    color: $danger;
  }
}
