@font-face {
  font-family: 'Inter';
  src: url(InterVariable.woff2) format('woff2');
  font-weight: 1 999;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url(InterVariable-Italic.woff2) format('woff2');
  font-weight: 1 999;
  font-style: italic;
  font-display: swap;
}
