.footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: $secondary;

  a:link,
  a:visited,
  a:active {
    color: $secondary;
    font-weight: inherit;
  }

  a:hover {
    text-decoration: underline;
  }
}
