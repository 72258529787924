.empty {
  width: 100%;
  max-width: 30rem;

  &-header {
    margin: 0 0 1rem;
    font-size: 4rem;
    font-weight: 300;
    line-height: 1;
    color: $secondary;
  }

  &-title {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 700;
  }

  &-subtitle {
    color: $secondary;
  }

  &-container {
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
  }

}