.btn {
  $root: &;
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;

  .tabler-icon {
    width: 1em;
    height: 1em;
  }

  &#{$root}-loading {
    .tabler-icon {
      animation: rotate360 1.2s infinite linear;
    }
  }
}

.btn-cancel {
  @include button-variant(
          $background: #fff,
          $border: #dce1e7,
          $color: $body-color,
          $hover-background: #fff,
          $hover-border: #aab2bf,
          $hover-color: $body-color,
          $active-background: rgba(var(--tblr-primary-rgb), 0.04),
          $active-border: $primary,
          $active-color: $primary
  );
}

.btn-primary {
  @include button-variant(
          $background: $primary,
          $border: transparent,
          $color: $white,
          $hover-background: rgba(32, 107, 196, .8),
          $hover-border: transparent,
          $hover-color: $white,
          $active-background: rgba(32, 107, 196, .8),
          $active-border: transparent,
          $active-color: $white,
          $disabled-background: $primary,
          $disabled-border: transparent,
          $disabled-color: #f6f8fb
  );
}

.btn-secondary {
  @include button-variant(
          $background: $secondary,
          $border: transparent,
          $color: $white,
          $hover-background: rgba(108, 122, 145, .8),
          $hover-border: transparent,
          $hover-color: $white,
          $active-background: rgba(108, 122, 145, .8),
          $active-border: transparent,
          $active-color: $white,
          $disabled-background: $secondary,
          $disabled-border: transparent,
          $disabled-color: #f6f8fb
  );
}

.btn-success {
  @include button-variant(
          $background: $success,
          $border: transparent,
          $color: $white,
          $hover-background: rgba(47, 179, 68, .8),
          $hover-border: transparent,
          $hover-color: $white,
          $active-background: rgba(47, 179, 68, .8),
          $active-border: transparent,
          $active-color: $white,
          $disabled-background: $green,
          $disabled-border: transparent,
          $disabled-color: #f6f8fb
  );
}

.btn-danger {
  @include button-variant(
          $background: $danger,
          $border: transparent,
          $color: $white,
          $hover-background: rgba(214,57,57, .8),
          $hover-border: transparent,
          $hover-color: $white,
          $active-background: rgba(214,57,57, .8),
          $active-border: transparent,
          $active-color: $white,
          $disabled-background: $danger,
          $disabled-border: transparent,
          $disabled-color: #f6f8fb
  );
}


.btn-warning {
  @include button-variant(
          $background: $warning,
          $border: transparent,
          $color: $white,
          $hover-background: rgba(247,103,7, .8),
          $hover-border: transparent,
          $hover-color: $white,
          $active-background: rgba(247,103,7, .8),
          $active-border: transparent,
          $active-color: $white,
          $disabled-background: $warning,
          $disabled-border: transparent,
          $disabled-color: #f6f8fb
  );
}

.btn-info {
  @include button-variant(
          $background: $info,
          $border: transparent,
          $color: $white,
          $hover-background: rgba(66,153,225, .8),
          $hover-border: transparent,
          $hover-color: $white,
          $active-background: rgba(66,153,225, .8),
          $active-border: transparent,
          $active-color: $white,
          $disabled-background: $info,
          $disabled-border: transparent,
          $disabled-color: #f6f8fb
  );
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
