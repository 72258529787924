.table {

  // Empty table used for no items and loading/error states
  &-empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;

    &-icon {
      max-width: 20rem;
      width: 100%;
      display: flex;
      align-items: center;
      pointer-events: none;
    }
  }
}