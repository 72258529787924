.page {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  position: relative;
  z-index: 1;

  &-header {
    position: relative;
    z-index: 1;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    color: white;
  }

  &-pretitle {
    font-size: 0.625rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    line-height: 1rem;
    color: rgb(108, 122, 145); // TODO: Extract var
  }

  &-title {
    margin: 0;
    line-height: 1.75rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    color: inherit;
  }
}
