nav#menu {
  --sidebar-shadow: 0px 4px 50px var(--highlight-bg);
  --sidebar-border: 1px solid transparent;

  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  width: 18rem;
  background: var(--surface-card);
  border-right: var(--surface-border);
  box-shadow: var(--sidebar-shadow);
  display: flex;
  flex-direction: column;
  color: var(--text-color);
}

#content {
  margin-left: 18rem;
  padding: 2rem;
}
