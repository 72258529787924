.navbar {
  box-sizing: border-box;

  &::after {
    content: '';
    height: 9rem;
    position: absolute;

    border-top: 1px solid rgba(255, 255, 255, 0.1);
    top: 100%;
    left: 0;
    right: 0;
    background-color: inherit;
    box-shadow: inherit;
    z-index: 0;
  }

  .nav-link {
    display: flex;
    align-items: center;

    .tabler-icon {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 1.25rem;
      margin-right: 0.5rem;
      stroke-width: 1.5;
    }
  }
}
